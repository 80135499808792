import type { SharedEnv } from '@shared/services/config/config.type';

import { ENVIRONMENT } from '@evc/platform';

export { ENVIRONMENT } from '@evc/platform';

export type AppEnv = SharedEnv

export const environment: AppEnv = {
  APP_NAME: 'organization',
  ENV: ENVIRONMENT.production,
  GREENFIELD: true,
  AUTH_FORCE_ORGANIZATION: false,
};
