import { JsonPipe } from '@angular/common';
import type { AfterViewInit, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import type { FormData, FormModel } from '@app/components/form/form.type';
import { StepAddressComponent } from '@app/components/step-address/step-address.component';
import { ObjToKeysPipe } from '@shared/pipes/object-to-keys.pipe';
import { EvcFormService } from '@shared/reactive-form/reactive-form.service';
import { EvcValidatorsService, patterns } from '@shared/reactive-form/validators/validator.service';
import { SharedConfigService } from '@shared/services/config/config.service';
import { I18nService, TranslatePipe } from '@shared/services/i18n/i18n.service';

import type { ApiOrganizationPayload } from '@evc/platform';
import { OrganizationsService } from '@evc/platform';
import type { Maybe } from '@evc/web-components';
import { InputComponent, NavButtonComponent, StatusIndicatorComponent, SvgIconComponent } from '@evc/web-components';

import { StepInfosComponent } from '../step-infos/step-infos.component';

export enum FormStep {
  INFOS,
  ADDRESS,
  SCREENING,
}
@Component({
  standalone: true,
  selector: 'evc-form',
  templateUrl: 'form.component.html',
  styleUrl: 'form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    TranslatePipe,
    JsonPipe,
    ObjToKeysPipe,
    InputComponent,
    NavButtonComponent,
    SvgIconComponent,
    StatusIndicatorComponent,
    StepInfosComponent,
    StepAddressComponent,
  ],
  providers: [EvcFormService], // EvcFormService<FormData>
})
export class FormComponent implements OnInit, AfterViewInit {
  @ViewChild(FormGroupDirective) formDirective!: FormGroupDirective;

  #destroyRef = inject(DestroyRef);
  #formBuilder = inject(FormBuilder);
  #formService = inject(EvcFormService<FormData>);// EvcFormService<FormData>
  #validatorService = inject(EvcValidatorsService);
  readonly #configService= inject(SharedConfigService);
  readonly #i18nService= inject(I18nService);
  readonly #organizationsService= inject(OrganizationsService);

  form!: FormGroup<FormModel>;
  step = signal(FormStep.INFOS);
  submited = this.#formService.submited;
  submitedStep = signal<Maybe<number>>(undefined);

  model = signal<FormData>({
    name: '',
    phone: '',
    address: {
      country: '',
      state: '',
      street: '',
      zip: '',
      city: '',
    },
  });
  ngOnInit(): void {
    const control = this.#formService.controlFactory(this.#formBuilder.nonNullable, this.model());

    this.form = this.#formBuilder.group<FormModel>({
      // - we create base profile fileds - so child form `infos` can simply use them
      name: control('name', [
        this.#validatorService.minLength(3),
        this.#validatorService.maxLength(25),
        this.#validatorService.pattern(patterns.alphanumericWithDash, 'noSpecialChars'),
      ]),
      phone: control('phone', [this.#validatorService.phone()]),
      // - we let the child form `address` creating it's own formGroup - it will be merged here
      // address: @see StepAddressComponent,
    });

    this.#formService.onUpdate$
    .pipe(
      takeUntilDestroyed(this.#destroyRef),
    ).subscribe((data) => {
      this.model.update((model) => ({ ...model, ...data }));
    });
  }

  ngAfterViewInit(): void {
    this.#formService.bind(this.form, this.formDirective, this.#destroyRef);
  }

  onBack(event:Event):void {
    const step = this.step();
    const prevStep = step - 1;
    this.submitedStep.set(prevStep - 1);
    event.preventDefault();
    if (step > 0) {
      this.step.set(prevStep);

      return;
    }
    this.#redirectToApp('back');
  }

  /**
   * When click submit cta
   * - trigger validation
   * - if valid, go to next step
   * - if final step, will submit data to api
   */
  onSubmitStep(event:Event):void {
    event.preventDefault();
    const step = this.step();
    this.submitedStep.set(step);
    this.form.markAllAsTouched();

    if (!this.validate(step)) return;

    switch (step) {
      case FormStep.INFOS:
        this.step.set(FormStep.ADDRESS);
        break;
      case FormStep.ADDRESS:
        this.#organizationsService.createOrganization(this.form.value as ApiOrganizationPayload)
        .then(() => {
          this.step.set(FormStep.SCREENING);
        });

        break;
      case FormStep.SCREENING:
      default:
        this.#redirectToApp('screening');
    }
  }

  validate(step=FormStep.INFOS):boolean {
    const isValid = (() => {
      switch (step) {
        case FormStep.INFOS:
          return this.form.get('name')?.valid
            && this.form.get('phone')?.valid;
        case FormStep.ADDRESS:
          return this.form.get('address')?.valid;
        default:
          return true;
      }
    })();

    return isValid ?? false;
  }

  /** redirect to the other app
   * - may fail if no other orgs AND (TODO) otherApp need orgs
   * - or succeed if have other orgs OR (TODO) otherApp need no orgs
   * - add url param ?reason= auth-org--screening|auth-org--back
   */
  #redirectToApp(reason?:string):void {
    const uri = this.#configService.get('redirectUri') as string;
    const url = new URL(uri);
    if (reason) url.searchParams.append('reason', `auth-org--${reason}`);
    window.location.href = url.href;
  }
}
