export type CountryType = 'NONE' | 'state' | 'province' | 'region';
export type Country = {
  value: string;
  label: string;
  states: { value: string; label: string }[];
  // used for labeling state vs province...
  type?: CountryType
};
export const MOCKED_SINGLE_PROVINCE = [
  { value: 'moc', label: 'Mock province' },
];
export const MOCKED_MULTI_PROVINCE = [
  { value: 'QC', label: 'Quebec' },
  { value: 'ON', label: 'Ontario' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'AB', label: 'Alberta' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'NT', label: 'Northwest Territories' },
  { value: 'NU', label: 'Nunavut' },
  { value: 'YT', label: 'Yukon' },
];
export const COUNTRIES: Country[] = [
  { value: 'AU', label: 'Australia', states: MOCKED_SINGLE_PROVINCE, type: 'state' },
  { value: 'BR', label: 'Brazil', states: MOCKED_SINGLE_PROVINCE, type: 'state' },
  { value: 'CA', label: 'Canada', states: MOCKED_MULTI_PROVINCE, type: 'province' },
  { value: 'CN', label: 'China', states: MOCKED_SINGLE_PROVINCE, type: 'province' },
  { value: 'DE', label: 'Germany', states: [] },
  { value: 'ES', label: 'Spain', states: [] },
  { value: 'FR', label: 'France', states: [] },
  { value: 'GB', label: 'United Kingdom', states: [] },
  { value: 'IN', label: 'India', states: MOCKED_SINGLE_PROVINCE, type: 'state' },
  { value: 'IT', label: 'Italy', states: [] },
  { value: 'JP', label: 'Japan', states: MOCKED_SINGLE_PROVINCE, type: 'region' },
  { value: 'PH', label: 'Philippines', states: MOCKED_SINGLE_PROVINCE, type: 'province' },
  { value: 'RU', label: 'Russia', states: MOCKED_SINGLE_PROVINCE, type: 'region' },
  { value: 'US', label: 'United States', states: MOCKED_SINGLE_PROVINCE, type: 'state' },
  { value: 'ZA', label: 'South Africa', states: [] },
];
