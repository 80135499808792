import { Validators } from '@angular/forms';
import { of } from 'rxjs';

/**
 * Simple Validation with If condition
 */
function ifValidator(condition, validatorFn) {
    return (control) => {
        if (!validatorFn || !condition(control)) {
            return null;
        }
        const validatorFns = Array.isArray(validatorFn)
            ? validatorFn
            : [validatorFn];
        return Validators.compose(validatorFns)?.(control) ?? null;
    };
}
/**
 * With Async Validation
 */
function ifAsyncValidator(condition, validatorFn) {
    return (control) => {
        if (!validatorFn || !condition(control)) {
            return of(null);
        }
        return validatorFn(control);
    };
}

/**
 * Generated bundle index. Do not edit.
 */

export { ifAsyncValidator, ifValidator };

