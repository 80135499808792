@if (ready()) {
  <nav class="navigation">
    <a class="navigation_link"
      href="{{ 'navigation.href' | translate }}"
      title="{{ 'navigation.title' | translate }}">
      <evc-svg-icon icon="angle-left" size="lg" />
      <img src="/assets/logo.svg" alt="{{ 'navigation.title' | translate }}">
    </a>
  </nav>
  <main class="main panel">
    <router-outlet/>
  </main>
}