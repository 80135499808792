import { JsonPipe } from '@angular/common';
import type { AfterViewInit, OnInit, Signal, WritableSignal } from '@angular/core';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, effect, inject, Input, signal, untracked } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import type { FormModelAddress } from '@app/components/form/form.type';
import { FormDataAddress } from '@app/components/form/form.type';
import { EvcFormService } from '@shared/reactive-form/reactive-form.service';
import type { FormGroupWithControls } from '@shared/reactive-form/types/reactive-form.type';
import { EvcValidatorsService, patterns } from '@shared/reactive-form/validators/validator.service';
import { TranslatePipe } from '@shared/services/i18n/i18n.service';
import { ifValidator } from 'ngxtension/if-validator';

import { InputComponent } from '@evc/web-components';

import type { Country } from '../../mocks/countries.mock';
import { COUNTRIES } from '../../mocks/countries.mock';

export type StepAddressFormGroupWithControls = FormGroupWithControls<{
  address?: FormGroup<FormModelAddress>
}>

@Component({
  selector: 'evc-form-address',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    TranslatePipe,
    InputComponent,
    JsonPipe,
  ],
  templateUrl: './step-address.component.html',
  providers: [EvcFormService],
})
export class StepAddressComponent<TFormGroupParent extends StepAddressFormGroupWithControls> implements OnInit, AfterViewInit {
  @Input({ required: true }) submited = false;
  @Input({ required: true }) model!:FormDataAddress;
  @Input({ required: true }) formGroupParent!:TFormGroupParent;

  fields:string[] = [];
  formGroup!: FormGroup<FormModelAddress>;

  countries = COUNTRIES;
  #currentCountryCode:WritableSignal<string|undefined> = signal(undefined);
  get currentCountry():Signal<Country|undefined> {
    return computed(() => {
      const countryCode = this.#currentCountryCode();

      return COUNTRIES.find(({ value }) => value === countryCode);
    });
  }
  states = computed(() => {
    const country = this.currentCountry();

    return {
      options: country?.states ?? [],
      type: country?.type ?? 'no-states',
    };
  });
  currentCountryHasStates = computed(() => this.states().options.length > 0);
  zipOrPostalCodeLabel = computed(() =>
    ['US', 'PH'].includes(this.#currentCountryCode()??'')
      ? 'zip'
      : 'postalCode',
  );

  #destroyRef = inject(DestroyRef);
  #formBuilder = inject(FormBuilder);
  #formService = inject(EvcFormService<FormDataAddress>);
  #validatorService = inject(EvcValidatorsService);

  /** may create it's own formGroup and inject to main parent form
   * if already exist resuse it to keep current state
   */
  ngOnInit(): void {
    this.fields = Object.keys(this.model);

    const currentAddressControls = this.formGroupParent.controls.address;
    if (currentAddressControls) {
      this.formGroup = currentAddressControls;

      return;
    }

    this.formGroup = this.#createControls();
    this.formGroupParent.setControl('address', this.formGroup);

    return;
  }
  ngAfterViewInit(): void {
    this.#syncCountryStates();
  }

  e = effect(() => {
    const enabled = this.currentCountryHasStates();
    untracked(() => {
      const field = this.formGroup.get('state');
      const action = enabled ? 'enable' : 'disable';
      field?.[action]?.();
    });
  });

  #createControls(): FormGroup<FormModelAddress> {
    const control = this.#formService.controlFactory(this.#formBuilder.nonNullable, this.model);

    return this.#formBuilder.group<FormModelAddress>({
      country: control('country'),
      state: control('state', [ifValidator(
          () => this.currentCountryHasStates(),
          [this.#validatorService.required()],
        )], false),
      street: control('street', [this.#validatorService.pattern(patterns.alphanumericExtended)]),
      zip: control('zip', [this.#validatorService.zipCode()]),
      city: control('city', [this.#validatorService.pattern(patterns.alphanumericExtended)]),
    });
  }

  #syncCountryStates():void {
    const countryField = this.formGroup.get('country')!;
    const currentCountry = countryField.value;
    if (currentCountry) {
      this.#currentCountryCode.set(currentCountry);
    }

    countryField.valueChanges
    .pipe(takeUntilDestroyed(this.#destroyRef))
    .subscribe(value => {
      this.#currentCountryCode.set(value ?? undefined);
      this.formGroup.get('state')!.setValue('');
    });
  }
}
