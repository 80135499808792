import type { OnInit, Signal } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FormComponent } from '@app/components/form/form.component';
import { TestSharedComponent } from '@shared/components/test-shared/test-shared.component';
import { SharedConfigService } from '@shared/services/config/config.service';
import { I18nService, TranslatePipe } from '@shared/services/i18n/i18n.service';

import { PlatformConfigService, PlatformService } from '@evc/platform';
import { SvgIconComponent, ThemeService } from '@evc/web-components';

@Component({
  standalone: true,
  imports: [FormComponent, TestSharedComponent, TranslatePipe, SvgIconComponent, RouterOutlet],
  selector: 'evc-root',
  templateUrl: 'app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  readonly #config = inject(SharedConfigService);
  readonly #platformConfig = inject(PlatformConfigService);
  readonly #platformService = inject(PlatformService);
  readonly #i18nService = inject(I18nService);
  readonly #themeService = inject(ThemeService);

  title?:string = this.#config.get('app').title;
  get ready():Signal<boolean> {
    return this.#platformService.ready;
  }

  ngOnInit(): void {
    this.#themeService.theme = 'light';
    this.#platformService.init(this.#i18nService);
    this.#setRedirectConfig();
  }

  /**
   * remember where to redirect on quit this flow
   * - ?redirect_uri may link to app if user have other orgs
   * - or redirect to public website if user have no active org
   */
  #setRedirectConfig() {
    const url = new URL(window.location.href);
    const defaultRedirects = this.#config.get('auth')!.redirects;

    const redirectUri = url.searchParams.get('redirect_uri')
      ?? defaultRedirects.fail;

    this.#config.set('redirectUri', redirectUri);
  }
}
