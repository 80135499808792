import { ENVIRONMENT } from '@evc/platform';
import type { OrganizationEnv } from '@app/types/config.type';

export { ENVIRONMENT } from '@evc/platform';

export const environment: OrganizationEnv = {
  APP_NAME: 'organization',
  ENV: ENVIRONMENT.production,
  GREENFIELD: true,
  AUTH_FORCE_ORGANIZATION: false,
};
