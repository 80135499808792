import type { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from '@env';

import { AuthFailureGuard, AuthLogoutGuard } from '@evc/platform';
import type { ImportAsyncComponent } from '@shared/types/utils.type';

const DEBUG_DISABLE_GUARD_ON_DEV = environment.DEBUG_DISABLE_AUTH
  ? { canActivate: undefined }
  : {};

const importPageNotFoundComponent:ImportAsyncComponent = () => import(
  '@shared/components/page-not-found/page-not-found.component'
).then(m => m.PageNotFoundComponent);

export const appRoutes: Route[] = [
  {
    path: 'logout',
    canActivate: [AuthLogoutGuard],
    loadComponent: importPageNotFoundComponent,
    data: { logout: true },
  },
  {
    path: 'auth-fail',
    canActivate: [AuthFailureGuard],
    loadComponent: importPageNotFoundComponent,
  },
  {
    path: '',
    loadComponent: () => import(
      '@app/pages/create/create.page'
    ).then(m => m.CreatePageComponent),
    canActivate: [MsalGuard],
    ...DEBUG_DISABLE_GUARD_ON_DEV,
  },
  {
    path: 'invitation/:token',
    loadComponent: () => import(
      '@app/pages/invitation/invitation-intro.page'
    ).then(m => m.InvitationIntroPageComponent),
    data: { step: 'intro' },
  },
  {
    path: 'invitation-success',
    loadComponent: () => import(
      '@app/pages/invitation/invitation-redirect.page'
    ).then(m => m.InvitationRedirectPageComponent),
    canActivate: [MsalGuard],
    data: { step: 'success' },
  },
  {
    path: '**',
    redirectTo: 'auth-fail',
  },
];
