import type { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { FormComponent } from './components/form/form.component';

export const appRoutes: Route[] = [
    {
      path: '**',
      component: FormComponent,
      canActivate: [MsalGuard],
    },
  ];
