<ng-container [formGroup]="formGroup" >
  <div class="organization-form__group">

    <evc-input formControlName="country"
      type="select"
      name="country"
      [label]="'form.country.label'|translate"
      [placeholder]="'form.country.placeholder'|translate"
      [showError]="submited"
      [options]="countries"
    ></evc-input>

    @if (states() ; as states) {
      <evc-input formControlName="state"
        type="select"
        name="state"
        [label]="'form.'+states.type+'.label'|translate"
        [placeholder]="'form.'+states.type+'.placeholder'|translate"
        [showError]="submited"
        [options]="states.options"
      ></evc-input>
      <pre>{{formGroup.get('state')?.enabled}}</pre>
    }

    @for (item of ['street', 'zip', 'city']; track $index) {
      <evc-input [formControlName]="item"
        [name]="item"
        [label]="'form.'+(item === 'zip' ? zipOrPostalCodeLabel() : item)+'.label'|translate"
        [placeholder]="'form.'+(item === 'zip' ? zipOrPostalCodeLabel() : item)+'.placeholder'|translate"
        [showError]="submited"
      ></evc-input>
    }
  </div>
</ng-container>