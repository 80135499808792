
@if(formGroupParent){
  <ng-container [formGroup]="formGroupParent" >
    <div class="organization-form__group">
      @for (item of ['name', 'phone']; track $index) {
        <evc-input [formControlName]="item"
          [name]="item"
          [label]="'form.'+item+'.label'|translate"
          [placeholder]="'form.'+item+'.placeholder'|translate"
          [showError]="submited"
        ></evc-input>
      }
    </div>
  </ng-container>
}