<form action=""
  name="organization"
  class="organization-form"
  [formGroup]="form">
  <header class="header">
    @if (step() === 2) {
      <h1 class="title title--screening">{{ 'screening.title' | translate }}</h1>
      <h2 class="description">{{ 'screening.text' | translate }}</h2>
    }@else {
      <h1 class="title">{{ 'header.title' | translate }}</h1>
      <h2 class="subtitle">{{ 'header.subtitle' | translate }}</h2>
    }
  </header>

  @switch(step()){
    @case(0) {
      <evc-form-infos [submited]="submitedStep() === 0" [formGroupParent]="form" />
    }
    @case(1) {
      <evc-form-address [submited]="submitedStep() === 1" [formGroupParent]="form" [model]="model().address!" />
    }
    @default {<!-- SCREENING handled in header/footer -->}
  }

  <div class="actions">
    @if (step() === 2) {
      <input (click)="onSubmitStep($event)" class="actions_submit actions_submit--screening" type="submit" [value]="'screening.submit'|translate" />
    }@else {
      <!-- flex order reverse so enter trigger submit -->
      <input (click)="onSubmitStep($event)" class="actions_submit" type="submit" [value]="'form.submit'|translate" />
      <evc-button class="actions_cancel" type="tertiary" [attr.type]="'button'" (click)="onBack($event)">{{ 'form.back'|translate }}</evc-button>
    }
  </div>
</form>

<!-- @if (step() < 2) {
  <pre class="_debug">
    step : {{step() | json}}
    submitedStep : {{submitedStep() | json}}
    {{model()|json}}
  </pre>
} -->