import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type FormGroup, ReactiveFormsModule } from '@angular/forms';
import type { FormModel } from '@app/components/form/form.type';
import { EvcFormService } from '@shared/reactive-form/reactive-form.service';
import { TranslatePipe } from '@shared/services/i18n/i18n.service';

import { InputComponent } from '@evc/web-components';

type FormModelStepInfos = Pick<FormModel, 'name'|'phone'>;

@Component({
  selector: 'evc-form-infos',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    TranslatePipe,
    InputComponent,
    JsonPipe,
  ],
  templateUrl: './step-infos.component.html',
  providers: [EvcFormService],
})
export class StepInfosComponent<TFormGroupParent extends FormGroup<FormModelStepInfos>> {
  @Input({ required: true }) submited = false;
  @Input({ required: true }) formGroupParent:TFormGroupParent = undefined as unknown as TFormGroupParent;
}
